@import-normalize;

:root {
  --color1: #eb5b29;
  --color2: rgba(235, 91, 41, 0.15);
  --color3: var(--color1);
  --color4: #84ce55;
  --color5: #434357;
  --color6: #ffffff;
}

@font-face {
  font-family: "Bristol";
  src: url("Renesans-Regular.otf");
  src: local("Bristol"), url("Renesans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Calc";
  src: url("DigitalNumbers-Regular.ttf");
  src: local("Calc"), url("DigitalNumbers-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Gothic";
  src: url("ReadexPro-Regular.ttf");
  src: local("Gothic"), url("ReadexPro-Regular.ttf") format("opentype");
}

html,
body,
div,
h1 {
  margin: 0;
  padding: 0;
}
* {
  font-size: 16px;
  font-family: Gothic;
}
html {
  font-size: 16pt;
  font-family: Gothic;
  color: var(--color1);
  line-height: 1.5em;
}

body {
  // background: var(--color3);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  width: 90%;
  max-width: 1400px;
  min-width: 320px;
  margin: 0 auto;
  // background: var(--color2);

  & > main {
    flex: 1 1 auto;
  }

  @media (max-width: 1560px) {
    width: 100%;
    max-width: auto;
  }
}

h1 {
}

h2 {
  color: var(--color1);
  font-size: 20pt;
  font-weight: normal;
  text-align: center;
}

p {
}

.button-list {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: right;
  flex-wrap: wrap;
  margin: 0.5em auto;

  .button-hand {
    position: relative;
    bottom: 0;
    right: 3em;
    height: 2em;
    border: none;
    width: 4em;
    margin: 0em 0;
    background-image: url("./assets/right_hand.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
  }
}

button {
  font-family: "Gothic";
}

.hide-in-portrait {
  @media (orientation: portrait) {
    display: none !important;
  }
}

.hide-in-landscape {
  @media (orientation: landscape) {
    display: none !important;
  }
}
.memoryImages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  img {
    width: 6em;
  }
}
.button-check-list {
  margin: 0 auto;
  margin-top: 1em;
  width: fit-content;
  display: block;
  grid-template-rows: 1fr;
  // grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  justify-content: center;
  gap: 1em;
  justify-content: center;
  align-items: center;

  button {
    font-family: Calc;
    margin: 0 0.5em;
    width: fit-content;
    // min-height: 5em;
    font-size: 1em;
    background-color: var(--color1);
    color: var(--color6);
    border-radius: 5px;
  }
  button:hover {
    background-color: #434357;
  }
}

.page-view {
  display: flex;
  justify-content: end;

  .page-img {
    position: fixed;
    max-width: 30%;
    margin: 0 auto !important;
    margin-top: 2em !important;
    bottom: 0;
    left: 5%;
  }
  .img-slon {
    left: 0 !important;
    max-width: 20%;
  }
  p,
  .p {
    display: flex;
    width: 50%;
    justify-content: end;
    background-color: var(--color1);
    color: var(--color6);
    border-radius: 1em;
    padding: 1.5em;
    height: min-content;
    font-size: 16px;
    line-height: normal;
  }
}
.page-view-slon {
  display: flex;
  justify-content: center;
  margin: 2em 0;
  p {
    display: flex;
    width: max-content;
    margin: 0 2em;
  }
}
.cryptex3 {
  img {
    width: 5em;
  }
  .arr_up,
  .arr_down {
    img {
      width: 2em;
      padding: 0;
    }
  }
  // display: grid;
  .crypInside {
    margin: 0 auto;
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    // gap: 5px;
  }
  .crypBlock {
    justify-content: center;
    justify-items: center;
    align-items: center;
    max-width: 5em;
    width: -webkit-fill-available;
    display: grid;
    text-align: center;
    height: max-content;
    // width: max-content;
    // height: 8em;

    p {
      width: 3em;
      max-width: 4em;
      background-color: rgba(102, 102, 102, 255);
      text-align: center;
      min-height: 8em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      border-radius: 0.5em;
      border-left: 5px solid rgba(140, 140, 140, 255);
      border-right: 5px solid rgba(140, 140, 140, 255);
    }
  }
  .crypImgLeft {
    transform: scaleX(-1);
  }
  .crypImgLeft,
  .crypImgRight {
    display: flex;

    width: 5em;
    // height: min-content;
    :only-child {
      // width: -webkit-fill-available;
      // height: auto;
    }
  }
}
.cryptex2 {
  .arr_up,
  .arr_down {
    img {
      width: 2em;
      padding: 0;
    }
  }
  height: 60vh;
  // background: url("./assets/krypteks.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
}
.cryptex {
  .arr_up,
  .arr_down {
    img {
      width: 2em;
      padding: 0;
    }
  }
 
  display: grid;
  align-items: center;
  // padding: 2em;
  margin: 0 auto;
  padding: 2em;
  height: fit-content;
  // background: url("./assets/krypteks.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  div {
    align-items: center;
    width: 20vh;
  }
  p {
    display: flex;
    text-align: center;
    font-size: 1.5rem;
  }
  .crypInside {
    display: grid;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
    width: 60%;
    margin: 0 auto;
    // display: grid;
    // grid-template-rows: 1fr 1fr 1fr ;
    grid-gap: 2%;
  }
}
.crypImgLeft {
  transform: scaleX(-1);
}
.arr_up,
.arr_down {
  width: 2em;
  padding: 0;
}
.arr_up {
  // margin-left: 2em;
  background: none;
  border: none;
  // width: 7%;
}
.arr_down {
  background: none;
  border: none;
  // width: 7%;
  transform: rotate(180deg);
}
@media screen and (max-width: 600px) {
  .cryptex {
    p {
      font-size: 1.5rem;
    }
  }
}
.stern_div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  img {
    margin: 2em;
    width: 50%;
    // height: 40%;
  }
  p {
    font-size: 0.7em;
    line-height: 1.2em;
  }
}
.stern {
  display: flex;
  align-items: end;
  transform: scaleX(-1);
  width: 20%;
  // position: absolute;
}

.img-button-block {
  position: fixed;
  bottom: 0;
  right: 6em;
  // margin-top: -5em;
  img {
    display: grid;
    // align-items: end;
    text-align: right;
    width: 60vh;
  }
}
.paragraph-find {
  padding: 0;
  margin: 0;
  min-height: 4em;
  height: fit-content;
  line-height: 1.5em;
  background-color: var(--color2);
  display: flex;
  align-items: center;
  padding-left: 2em;
}
.button-list-find {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: right;
  flex-wrap: wrap;
  // margin: 0.5em auto;

  .button-hand {
    position: relative;
    bottom: 1em;
    right: 3em;
    height: 2em;
    border: none;
    width: 4em;
    // margin-top: 2em;
    background-image: url("./assets/right_hand.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
  }
}
.quiz-button {
  display: none;
}
.password-view {
  width: 90%;
  margin: 0 auto;
  background-color: var(--color1);
  border: 2px solid var(--color1);
  border-radius: 2em;
  .password-view-title {
    text-align: center;

    h1,
    h2 {
      color: var(--color6) !important;
      margin: 0;
      padding: 0.2em;
    }
  }
  .input-field {
    display: grid;
    grid-template-rows: 2fr 1fr;
    margin: 0 auto;
    width: 99.5%;
    background-color: var(--color6);
    border: 2px solid var(--color1);
    border-radius: 2em;
    // padding-top: 2em;
    // margin-top: 2em;
    button {
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 1em;
    }
    #fname {
      height: 50%;
      border: none;
      text-align: left;
      max-width: min-content;
      color: var(--color1);
      font-size: 3em;
      display: block;
      text-transform: uppercase;
      width: 7em;
      margin: 0 auto;
      margin-top: 0.5em;

      // width: ;
    }
    #fname:focus {
      padding: 0;
      border-radius: 0;
      border: none;
    }
  }
}
.key {
  display: block;
  margin: 0 auto;
  text-align: center;
  color: black;
}
.task-paragraph {
  display: block;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
.page-view-poem {
  display: grid;
  grid-template-columns: 60% 40%;
  .main-p {
    margin: 1em 2em;
    width: auto;
    justify-content: flex-start;
    text-align: left;
  }
  .poem {
    font-weight: 900;
    text-align: center;
    font-size: 1em;
    background: none;
    color: var(--color1);
    white-space: nowrap;
    // width: 30%;
    // width: 20%;
  }
}
.poemGrid {
  margin: 0 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  div {
    font-size: 16px;
    gap: 0;
    p {
      margin: 0.3em;
      line-height: 1.4em;
    }
    .input-field {
      #fname {
        text-transform: uppercase;
      }
    }
  }
}
.memory {
  width: 100%;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    height: 5em;
    padding-left: 2em;
  }
}
.reaction{
  img{
    width: 9em;
    height: 11em;
  }
}
.crypNum{
  p{
    font-family: calc;
  }
  
}
.underQuote{
  line-height: 8px;
  letter-spacing: 1px;
  font-size: 6px;
  color: black;
  // max-width: 70%;
  margin: 0;
}